// extracted by mini-css-extract-plugin
export var onlineMarketplaceAchievementsSection = "y_d3";
export var onlineMarketplaceCardBorderSection = "y_dM";
export var onlineMarketplaceCardRowIconsSection = "y_dS";
export var onlineMarketplaceClientQuotesSection = "y_d4";
export var onlineMarketplaceCycleDevSection = "y_dN";
export var onlineMarketplaceCycleDevSection__testimonial = "y_dP";
export var onlineMarketplaceDomainExpertiseSection = "y_dQ";
export var onlineMarketplaceExceptionSection = "y_dR";
export var onlineMarketplaceFeaturedCaseSection = "y_dT";
export var onlineMarketplaceFeaturesSection = "y_dW";
export var onlineMarketplaceFeaturesSection__box = "y_dX";
export var onlineMarketplaceProjLogoSection = "y_d2";
export var onlineMarketplaceRequirementSection = "y_dY";
export var onlineMarketplaceSuccessStoriesSection = "y_d0";
export var onlineMarketplaceSuccessfulSection = "y_dV";
export var onlineMarketplaceTechStackSection = "y_dZ";
export var onlineMarketplaceWhyHireSection = "y_d1";